define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list-setting-shims", ["exports", "@ember/service", "discourse/lib/decorators", "discourse/lib/plugin-api"], function (_exports, _service, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-topic-list-setting-shims",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "agc-base-component topic-list-setting-shims",
          siteSettings: (0, _service.service)(),
          amazonianGroupName() {
            return this.siteSettings.amazonian_group_name;
          }
        }, [["method", "amazonianGroupName", [(0, _decorators.default)("siteSettings.amazonian_group_name")]]]));
      });
    }
  };
});